import { render, staticRenderFns } from "./ModalDuplicate.vue?vue&type=template&id=dd51c132&scoped=true"
import script from "./ModalDuplicate.vue?vue&type=script&lang=js"
export * from "./ModalDuplicate.vue?vue&type=script&lang=js"
import style0 from "./ModalDuplicate.vue?vue&type=style&index=0&id=dd51c132&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd51c132",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd51c132')) {
      api.createRecord('dd51c132', component.options)
    } else {
      api.reload('dd51c132', component.options)
    }
    module.hot.accept("./ModalDuplicate.vue?vue&type=template&id=dd51c132&scoped=true", function () {
      api.rerender('dd51c132', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modals/ModalDuplicate.vue"
export default component.exports