import gql from 'graphql-tag';

export default gql`
query getAllChildSlugsOfFolder($folderId: Int!) {
  folders(where: {parent_folder_id: {_eq: $folderId}}, order_by: {order_index: asc}) {
    folder_id
    slug
  }
  projects(where: {parent_folder_id: {_eq: $folderId}}, order_by: {order_index: asc}) {
    project_id
    slug
  }
  pages(where: {parent_folder_id: {_eq: $folderId}}, order_by: {order_index: asc}) {
    page_id
    slug
  }
}
`;
