<template>
  <div>
    <b-modal
      :id="`duplicate${slug}`"
      centered
      no-close-on-backdrop
      footer-class="flex-nowrap"
    >
      <template #modal-header>
        <h3>{{ $t('modals.duplicate.title') }}</h3>
      </template>
      <div class="p-5 text-center">
        <p class="mb-4 text-uppercase">{{ $t('modals.duplicate.intro', { type: pageType }) }}</p>
        <h3 class="mb-4">{{ itemName }}</h3>
        <p><small>{{ $t('modals.duplicate.desc') }}</small></p>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              :class="(formInvalid) ? 'is-invalid' : ''"
              v-model="newName"
              @input="setName"
              autofocus
              debounce="500"
            >
            </b-form-input>
            <div
              v-if="formInvalid"
              class="invalid-feedback"
            >
              {{ $t('webmag.insert_name') }}
            </div>
          </div>
          <div class="preview text-center mb-3"><small>{{ computedUrl }}</small></div>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="duplicateItem"
        >
          {{ $t('buttons.duplicate') }}
        </b-button>
      </template>
    </b-modal>
    <modal-acknowledge
      v-if="showAcknowledgeModal"
      action-type="duplicate"
      :page-type="pageType"
      @close-acknowledge-modal="closeAcknowledgeModal"
    ></modal-acknowledge>
  </div>
</template>

<script>
import DuplicateItem from '@/graphQlQueries/mutations/duplicateItem';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetOrderOfPages from '@/graphQlQueries/mutations/setOrderOfPages';
import UpdateItemOrderIndex from '@/graphQlQueries/mutations/updateItemOrderIndex';
import SetPublishStateOfPageById from '@/graphQlQueries/mutations/setPublishStateOfPageById';
import SetPublishStateOfProjectById from '@/graphQlQueries/mutations/setPublishStateOfProjectById';
import GetAllChildSlugsOfFolder from '@/graphQlQueries/queries/getAllChildSlugsOfFolder';
import GetAllChildSlugsOfProject from '@/graphQlQueries/queries/getAllChildSlugsOfProject';
import GetAllDescendantsOfItem from '@/graphQlQueries/queries/getAllDescendantsOfItem';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import Auth from '@aws-amplify/auth';
import { required } from 'vuelidate/lib/validators/index';
import { validationMixin } from 'vuelidate';
import slugify from '@sindresorhus/slugify';

export default {
  name: 'ModalDuplicate',
  props: [
    'groupDomain',
    'groupSlug',
    'parentItem',
    'itemId',
    'itemName',
    'slug',
    'pageType',
  ],
  components: {
    ModalAcknowledge: () => import('@/components/modals/ModalAcknowledge.vue'),
  },
  mixins: [UserDataAndAccess, SendPublishedDataToServer, validationMixin],
  data() {
    return {
      newSlug: null,
      newName: null,
      enableDescendentQuery: false,
      moveTo: null,
      descendentProjectIds: [],
      descendentPageIds: [],
      selectedGroupId: null,
      nextPosOfDestinationProjectsAndFolders: null,
      nextPosOfDestinationPages: null,
      showAcknowledgeModal: false,
    };
  },
  async created() {
    this.newSlug = (this.slug) ? `${this.slug}-copy` : null;
    this.newName = (this.itemName) ? `${this.itemName} [copy]` : null;
    // check for duplicates
    await this.checkForDuplicateSlugs();
  },
  mounted() {
    this.$bvModal.show(`duplicate${this.slug}`);
  },
  computed: {
    formInvalid() {
      if (this.$v.newName.$anyDirty && !this.newName) {
        return true;
      }
      if (this.$v.newName.$anyDirty && this.$v.newName.$anyError) {
        return true;
      }
      return false;
    },
    computedUrl() {
      // replace [group_slug] with group slug
      const groupPreviewDomain = process.env.VUE_APP_DOMAIN.replace('[group_slug]', this.groupSlug);
      const parentUrlPath = (this.$route.params.parametersOfUrl)
        ? `${this.$route.params.parametersOfUrl}/`
        : '';
      console.log('test new slug');
      return (this.newSlug)
        ? `${groupPreviewDomain}/${parentUrlPath}${this.newSlug}`
        : `${groupPreviewDomain}/${parentUrlPath}`;
    },
  },
  validations: {
    newName: {
      required,
    },
  },
  apollo: {
    allDescendentItems: {
      query: GetAllDescendantsOfItem,
      variables() {
        return {
          itemPath: 'thisIsADummyString',
        };
      },
      update(data) {
        if (data.project_descendants.length) {
          this.descendentProjectIds = data.project_descendants.map((item) => item.project_id);
        }
        if (data.page_descendants.length) {
          this.descendentPageIds = data.page_descendants.map((item) => item.page_id);
        }
        return data;
      },
    },
  },
  methods: {
    async setName(value) {
      this.newName = value;
      this.newSlug = slugify(value);
      this.$v.newName.$touch();
      await this.checkForDuplicateSlugs();
    },
    async duplicateItem() {
      this.$bvModal.hide(`duplicate${this.slug}`);
      // run the loader
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.parentSlugPath = (this.$route.params.parametersOfUrl)
        ? `/${this.$route.params.parametersOfUrl}`
        : null;
      // 1. check for duplicate slugs and names
      await this.checkForDuplicateSlugs();
      const dataToBeSendToTheServer = {
        slug: this.newSlug,
        parent_slug_path: this.parentSlugPath || '/',
        group_slug: this.groupSlug,
        group_domain: this.groupDomain,
        type: this.pageType,
        oldSlug: this.slug,
      };
      const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
        action: 'duplicateItem',
        data: dataToBeSendToTheServer,
      });

      if (publishedDataWasSuccessfulSend) {
        // run the action and create all duplicates
        const duplicateObject = {
          name: this.newName,
          slug: this.newSlug,
          destination_folder_id: (this.parentItem.type === 'folder') ? this.parentItem.id : null,
          destination_project_id: (this.parentItem.type === 'project') ? this.parentItem.id : null,
          page_id: (this.pageType === 'page') ? this.itemId : null,
          folder_id: (this.pageType === 'folder') ? this.itemId : null,
          project_id: (this.pageType === 'project') ? this.itemId : null,
        };
        const { data: { duplicateItem } } = await this.$apollo.mutate({
          mutation: DuplicateItem,
          variables: {
            object: duplicateObject,
          },
        });
        if (duplicateItem[this.pageType]) {
          await this.$apollo.queries.allDescendentItems.refetch({
            itemPath: duplicateItem[this.pageType].item_path,
          });
        }
        await this.unpublishDescendentProjectAndPages();
        await this.setTheOrderOfTheDuplicatedItem(duplicateItem);
        await this.$apollo.mutate({
          mutation: SetLoadingIndicatorInCache,
          variables: {
            isIndicatorLoading: false,
          },
        });
        this.showAcknowledgeModal = true;
        // create a new gist entry for project create
        try {
          const authenticatedUser = await Auth.currentAuthenticatedUser();
          const { email } = authenticatedUser.attributes;
          if (window.gist && email) {
            switch (this.pageType) {
              case 'folder':
                window.gist.identify(email, { email, tags: 'folders_trigger' });
                break;
              case 'project':
                window.gist.identify(email, { email, tags: 'projects_trigger' });
                break;
              case 'page':
                window.gist.identify(email, { email, tags: 'pages_trigger' });
                break;
              default:
                console.error('the page type doesn\'t exist', this.pageType);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async setTheOrderOfTheDuplicatedItem(duplicateItem) {
      switch (this.pageType) {
        case 'folder':
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id: null,
                folder_id: duplicateItem.folder.folder_id,
                current_order_index: duplicateItem.folder.order_index,
                new_order_index: 1,
                parent_folder_id: duplicateItem.folder.parent_folder_id,
              },
            },
          });
          break;
        case 'project':
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id: duplicateItem.project.project_id,
                folder_id: null,
                current_order_index: duplicateItem.project.order_index,
                new_order_index: 1,
                parent_folder_id: duplicateItem.project.parent_folder_id,
              },
            },
          });
          break;
        case 'page':
          await this.$apollo.mutate({
            mutation: SetOrderOfPages,
            variables: {
              pageId: duplicateItem.page.page_id,
              orderIndex: this.nextPosOfDestinationPages,
            },
          });
          break;
        default:
          console.log('no item to update');
      }
      return 'order of duplicate item set';
    },
    async checkForDuplicateSlugs() {
      let allSlugs = null;
      let isDuplicateSlug = null;

      if (this.parentItem.type === 'folder') {
        // check the slugs inside the folder
        const { data } = await this.$apollo.query({
          query: GetAllChildSlugsOfFolder,
          variables: {
            folderId: this.parentItem.id,
          },
          fetchPolicy: 'network-only',
        });
        allSlugs = this.getAllChildSlugsOfParentItem(data);
      }
      if (this.parentItem.type === 'project') {
        // check the slugs inside the folder
        const { data } = await this.$apollo.query({
          query: GetAllChildSlugsOfProject,
          variables: {
            projectId: this.parentItem.id,
          },
          fetchPolicy: 'network-only',
        });
        allSlugs = this.getAllChildSlugsOfParentItem(data);
      }
      // check if the item slug is in the array
      if (allSlugs) {
        isDuplicateSlug = allSlugs.includes(this.newSlug);
      }
      if (isDuplicateSlug) {
        // slug already exists so change the slug in the table!
        // do a mutation to change the slug!
        let newSlug;
        let i = null;
        // find the right postfix
        for (i = 1; i < 100; i++) {
          newSlug = `${this.newSlug}-${i}`;
          if (allSlugs && !allSlugs.includes(newSlug)) {
            break;
          }
        }
        this.newSlug = slugify(newSlug);
        return true;
      }
      return false;
    },
    getAllChildSlugsOfParentItem(data) {
      const slugArray = [];
      let folderLength = 0;
      let projectLength = 0;
      let pageLength = 0;
      if (data.folders) {
        data.folders.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
        folderLength = data.folders.length;
      }
      if (data.projects) {
        data.projects.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
        projectLength = data.projects.length;
      }
      if (data.pages) {
        data.pages.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
        pageLength = data.pages.length;
      }
      this.nextPosOfDestinationProjectsAndFolders = folderLength + projectLength + 1;
      this.nextPosOfDestinationPages = pageLength + 1;
      return slugArray;
    },
    closeAcknowledgeModal() {
      this.showAcknowledgeModal = false;
      this.$emit('item-duplicated');
    },
    async unpublishDescendentProjectAndPages() {
      // unpublish all the moved page and project items
      await Promise.all(this.descendentProjectIds.map(async (projectId) => {
        await this.$apollo.mutate({
          mutation: SetPublishStateOfProjectById,
          variables: {
            projectId,
            published: false,
          },
        });
      }));
      await Promise.all(this.descendentPageIds.map(async (pageId) => {
        await this.$apollo.mutate({
          mutation: SetPublishStateOfPageById,
          variables: {
            id: pageId,
            published: false,
          },
        });
      }));
      return 'updated publish status of project and page';
    },
  },
};
</script>

<style scoped lang="scss">
.show-url-icon svg {
  font-size: 80px;
}

/deep/ .disabled {
  cursor: not-allowed;
}
</style>
