var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: `duplicate${_vm.slug}`,
            centered: "",
            "no-close-on-backdrop": "",
            "footer-class": "flex-nowrap"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("modals.duplicate.title")))])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function({ close }) {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3 border-right-light-grey",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3",
                      attrs: { variant: "primary", block: "" },
                      on: { click: _vm.duplicateItem }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.duplicate")) + " ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", { staticClass: "p-5 text-center" }, [
            _c("p", { staticClass: "mb-4 text-uppercase" }, [
              _vm._v(
                _vm._s(_vm.$t("modals.duplicate.intro", { type: _vm.pageType }))
              )
            ]),
            _c("h3", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.itemName))]),
            _c("p", [
              _c("small", [_vm._v(_vm._s(_vm.$t("modals.duplicate.desc")))])
            ]),
            _c("div", [
              _c(
                "div",
                { staticClass: "mt-5 mx-5 mb-3" },
                [
                  _c("b-form-input", {
                    class: _vm.formInvalid ? "is-invalid" : "",
                    attrs: { autofocus: "", debounce: "500" },
                    on: { input: _vm.setName },
                    model: {
                      value: _vm.newName,
                      callback: function($$v) {
                        _vm.newName = $$v
                      },
                      expression: "newName"
                    }
                  }),
                  _vm.formInvalid
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(" " + _vm._s(_vm.$t("webmag.insert_name")) + " ")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "preview text-center mb-3" }, [
                _c("small", [_vm._v(_vm._s(_vm.computedUrl))])
              ])
            ])
          ])
        ]
      ),
      _vm.showAcknowledgeModal
        ? _c("modal-acknowledge", {
            attrs: { "action-type": "duplicate", "page-type": _vm.pageType },
            on: { "close-acknowledge-modal": _vm.closeAcknowledgeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }