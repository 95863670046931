import gql from 'graphql-tag';

export default gql`
mutation duplicateItem($object: DuplicateItemInput!) {
  duplicateItem(object: $object) {
    folder {
      folder_id
      item_path
      order_index
      parent_folder_id
      group {
        slug
        domain
      }
    }
    page {
      page_id
      item_path
      group {
        slug
        domain
      }
    }
    project {
      project_id
      item_path
      order_index
      parent_folder_id
      group {
        slug
        domain
      }
    }
  }
}
`;
